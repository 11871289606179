import axios from "axios";
import { linkUrl } from "../utilies/linkUrl";

const aboutService = async () => {
    const res = await axios.get(`${linkUrl}/about-us`);
    if (res.data.status === 200) {
        return res.data.data.aboutus;
    }
}

export default aboutService;
