import React, { useEffect, useState } from "react";
import BannerComponent from "../Components/Common/BannerComponent";
import BookAppointmentComponent from "../Components/Common/BookAppointmentComponent";
import aboutService from "../services/aboutServices";
import { imgUrl } from "../utilies/linkUrl";

const AboutUsComponent = () => {
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const aboutData = async () => {
      setIsLoading(true);
      const about_data = await aboutService();
      if (about_data) {
        setAbout(about_data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    aboutData();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="text-center">
         
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "4rem", height: "4rem" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <BannerComponent title="About-Us" subTitle="About us" />
          <section className="about-area about-p pb-120 p-relative fix pt-5">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {about && about.image && (
                    <div
                      className="s-about-img p-relative  wow fadeInLeft animated"
                      data-animation="fadeInLeft"
                      data-delay=".4s"
                    >
                      <img
                        src={about.image ? `${imgUrl}${about.image}` : ""}
                        style={{ objectFit: "cover" }}
                        alt="img"
                      />
                    </div>
                  )}
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div
                    className="about-content s-about-content  wow fadeInRight  animated"
                    data-animation="fadeInRight"
                    data-delay=".4s"
                  >
                    <div className="about-title second-title pb-25">
                      {about && about.title && (
                        <h2>{about.title ? about.title : ""}</h2>
                      )}
                    </div>
                    {about && about.description && (
                      <>
                        <p
                          className="mb-4"
                          dangerouslySetInnerHTML={{
                            __html: about.description,
                          }}
                        ></p>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-5 shadow p-2">
                <div
                  className="col-md-6 wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                  style={{ backgroundColor: "#d9e5ed" }}
                >
                  <div className="mb-4">
                    {about && about.experience && (
                      <>
                        <h4
                          className="d-inline-block text-primary text-uppercase border-5"
                          style={{ borderBottom: "2px solid #3f5ae1" }}
                        >
                          Our Experience
                        </h4>

                        <p
                          className="mb-4 text-justify text-dark"
                          dangerouslySetInnerHTML={{ __html: about.experience }}
                        ></p>
                      </>
                    )}

                    {about && about.skills && (
                      <>
                        <h4
                          className="d-inline-block text-primary text-uppercase border-5"
                          style={{ borderBottom: "2px solid #3f5ae1" }}
                        >
                          Our Skills
                        </h4>

                        <p
                          className="mb-4 text-justify text-dark"
                          dangerouslySetInnerHTML={{ __html: about.skills }}
                        ></p>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="col-md-6 wow fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  {about && about.mission && (
                    <>
                      <h4
                        className="d-inline-block text-primary text-uppercase border-5"
                        style={{ borderBottom: "2px solid #3f5ae1" }}
                      >
                        Mission
                      </h4>

                      <p
                        className="mb-4 text-justify text-dark"
                        dangerouslySetInnerHTML={{ __html: about.mission }}
                      ></p>
                    </>
                  )}

                  {about && about.vision && (
                    <>
                      <h4
                        className="d-inline-block text-primary text-uppercase border-5"
                        style={{ borderBottom: "2px solid #3f5ae1" }}
                      >
                        Vision
                      </h4>

                      <p
                        className="mb-4 text-justify text-dark"
                        dangerouslySetInnerHTML={{ __html: about.vision }}
                      ></p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

        
        </>
      )}
    </>
  );
};

export default AboutUsComponent;
