import React, { useEffect, useState } from "react";
import BannerComponent from "../Components/Common/BannerComponent";
import servicDataService from "../services/ourServices";
import { imgUrl } from "../utilies/linkUrl";
import { Link } from "react-router-dom";

const ServicesComponent = () => {
  const [services, setServices] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
      window.scrollTo(0, 0);
      const servicesData= async()=>{
          setIsLoading(true);
          const service = await servicDataService()
          if(service) {
              setServices(service) ;
              setIsLoading(false);
          }else{
              setIsLoading(false);
          }

      }
      servicesData()
  },[])
  return (
    <>
        {isLoading ? (
        <div className="text-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "4rem", height: "4rem" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
                <BannerComponent title="Our-Service" subTitle="Service" />
      <section
        id="service-details2"
        className="service-details-two pt-120 pb-85 p-relative"
      >
        <div className="container">
          <div className="row ">
          {
              services &&
                services.length > 0 &&
                services.map((service, idx) => (
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-15 wow fadeInDown animated mb-4"
                  data-animation="fadeInDown"
                  data-delay=".4s" key={idx}>
                    <Link to={`/service/${service.slug}`}>
                  <div className="card h-100">
                    <img
                      className="card-img-top"
                      src={`${imgUrl}${service.image}`} alt="Our Services"
                      height="180"
                    />
                    <div className="card-body">
                  
                      <h4 className="card-title text-center"> {service.title?service.title:""}</h4>
                     
                    </div>
                  </div>

                  </Link>
                </div>

                ))
          }

         
          </div>
        </div>
      </section>



        </>
      )
        }

    </>
  );
};

export default ServicesComponent;
