import React from 'react'
import { imgUrl } from '../../utilies/linkUrl';
import { Link } from 'react-router-dom';

const ProjectHomeComponent = ({project}) => {


  return (
    <section
      id="service-details2"
      className="service-details-two p-relative"
     
    >

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title center-align mb-50 text-center mt-20">
              {/* <h5></h5> */}
              <h2>Some Of Our Projects</h2>
            </div>


          </div>
          {
              project &&
                project.length > 0 &&
                project.slice(0, 4).map((service, idx) => (
               
                 
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-15 wow fadeInDown animated mb-4"
                  data-animation="fadeInDown"
                  data-delay=".4s" key={idx}>
                    <Link to={`/project/${service.slug}`}>
                  <div className="card h-100">
                    <img
                      className="card-img-top"
                      src={`${imgUrl}${service.image}`} alt="Our Projects"
                      height="180"
                    />
                    <div className="card-body">
                  
                      <h4 className="card-title text-center"> {service.title?service.title:""}</h4>
                     
                    </div>
                  </div>
                  </Link>
                </div>
                
                ))
          }
        </div>
      </div>
    </section>
  );
}

export default ProjectHomeComponent