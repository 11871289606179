import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import siteSetting from "../../services/settingServices";
import { imgUrl } from "../../utilies/linkUrl";

const NavBarComponent = () => {
  const [siteData, setSiteData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const servicesData = async () => {
      setIsLoading(true);
      const service = await siteSetting();

      if (service) {
        setSiteData(service);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    servicesData();
  }, []);
  return (
    <div id="header-sticky" className="menu-area">
      {/* {
        console.log(siteData)
      } */}
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <div className="logo" style={{ width:"70px" }}>
              <Link to="/">
                <img
                className="img-fluid"
              
                  src={
                    siteData &&
                    siteData.image
 &&
                    siteData.image
 !== null
                      ? `${imgUrl}${siteData.image
}`
                      : ""
                  }
                  alt="logo"
                />
                  {/* <p   style={{ color: ' #00b3f0' }}>{siteData && siteData.site_title ? siteData.site_title : ""}</p> */}
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 ps-md-5 ms-auto">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      "nav-link" + (isActive ? " active" : "")
                    }
                    end
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      "nav-link" + (isActive ? " active" : "")
                    }
                    end
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/services"
                    className={({ isActive }) =>
                      "nav-link" + (isActive ? " active" : "")
                    }
                    end
                  >
                    Service
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/projects"
                    className={({ isActive }) =>
                      "nav-link" + (isActive ? " active" : "")
                    }
                    end
                  >
                    Projects
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/contact-us"
                    className={({ isActive }) =>
                      "nav-link" + (isActive ? " active" : "")
                    }
                    end
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
      
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBarComponent;
