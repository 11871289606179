import React, { useEffect, useState } from "react";
import servicDataService from "../../services/ourServices";
import { imgUrl } from "../../utilies/linkUrl";
import { Link } from "react-router-dom";

const ServiceHomeComponent = ({services}) => {
  return (
    <section
      id="service-details2"
      className="service-details-two p-relative"
      style={{ background: "#57c8e1" }}
    >

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title center-align mb-50 text-center mt-20">
              <h5>Our Services</h5>
              <h2>What We Provide</h2>
            </div>


          </div>
          {
              services &&
                services.length > 0 &&
                services.slice(0, 4).map((service, idx) => (
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-15 wow fadeInDown animated mb-4"
                  data-animation="fadeInDown"
                  data-delay=".4s" key={idx}>
                    <Link to={`/service/${service.slug}`}>
                  <div className="card h-100">
                    <img
                      className="card-img-top"
                      src={`${imgUrl}${service.image}`} alt="Our Services"
                      height="180"
                    />
                    <div className="card-body">
                  
                      <h4 className="card-title text-center"> {service.title?service.title:""}</h4>
                     
                    </div>
                  </div>
                  </Link>
                </div>

                ))
          }
        </div>
      </div>
    </section>
  );
};

export default ServiceHomeComponent;
