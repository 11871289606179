import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { imgUrl, linkUrl } from "../utilies/linkUrl";
import axios from "axios";

const ProjectDetailPage = () => {
  const { slug } = useParams();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const projectDetails = async () => {
      setIsLoading(true);
      if (slug) {
        const res = await axios.get(`${linkUrl}/our-project/${slug}`);
        if (res.data.status === 200) {
          setData(res.data.data.ourproject);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    };
    projectDetails();
    setData("");
  }, [slug]);

  return (
    <>
    {isLoading ? (
    <div className="text-center">
      <div
        className="spinner-border text-primary"
        role="status"
        style={{ width: "4rem", height: "4rem" }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
  


 
      <div className="container mb-4">
        <div className="blog-grid-item-02 style-02 blog-details ">
          {data && data.title && (
            <h3 className="main-title mt-4">{data.title ? data.title : ""}</h3>
          )}
          {data && data.owner && (
            <h6 className="main-title mt-2 text-secondary">Owner :{data.owner ? data.owner : ""}</h6>
          )}
          {data && data.address && (
            <h6 className="main-title mt-2 text-secondary">
             Address : {data.address ? data.address : ""}
            </h6>
          )}
        </div>

        {data && (
          <div className="row">
            <div
              className="col-md-12 blog-details-content p-4 fs-6"
              style={{ backgroundColor: "#edeffc" }}
            >
              {data.image && (
                <img
                  style={{
                    float: "left",
                    width: "250px",
                    height: "200px",
                    marginRight: "10px",
                  }}
                  src={data.image ? `${imgUrl}${data.image}` : ""}
                  alt="Institute"
                />
              )}

              <p
                className="text-dark fw-bold"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></p>
             {/* {data && data.details
 && (
                <>
                  <h3 className="main-title mt-4 mb-2">
                    Project Details
                  </h3>
                  <div
                    className="text-dark fw-bold"
                    dangerouslySetInnerHTML={{
                      __html: data.details
                      ,
                    }}
                  ></div>
                </>
              )} */}
 
            </div>
          </div>
        )}
      </div>
  
  )}
    </>
  );
};

export default ProjectDetailPage;
