import axios from "axios";
import { linkUrl } from "../utilies/linkUrl";


const servicDataService = async () => {
    const res = await axios.get(`${linkUrl}/our-services`);
    if (res.data.status === 200) {
        return res.data.data.ourservices;
    }
}

export default servicDataService;
