import axios from "axios";
import { linkUrl } from "../utilies/linkUrl";


const projectDataService = async () => {
    const res = await axios.get(`${linkUrl}/our-projects`);
    if (res.data.status === 200) {
        return res.data.data.ourprojects;
    }
}

export default projectDataService;
