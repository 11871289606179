import React, { useEffect, useState } from 'react'
import projectDataService from '../services/projectService';
import BannerComponent from '../Components/Common/BannerComponent';
import { imgUrl } from '../utilies/linkUrl';
import { Link } from 'react-router-dom';

const ProjectsPage = () => {
    const [projectData, setProjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
    window.scrollTo(0, 0);
    const projectsData= async()=>{
        setIsLoading(true);
        const project = await projectDataService()
        if(project) {
            setProjectData(project) ;
            setIsLoading(false);
        }else{
            setIsLoading(false);
        }

    }
    projectsData()
},[])
  return (
    <>
    {isLoading ? (
    <div className="text-center">
      <div
        className="spinner-border text-primary"
        role="status"
        style={{ width: "4rem", height: "4rem" }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
            <BannerComponent title="Our-Projects" subTitle="Our Projects" />
  <section
    id="service-details2"
    className="service-details-two pt-120 pb-85 p-relative"
    style={{ backgroundColor:"#adf0ff" }}
  >
    <div className="container" >
      <div className="row ">
      {
          projectData &&
            projectData.length > 0 &&
            projectData.map((service, idx) => (
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15 wow fadeInDown animated mb-4"
              data-animation="fadeInDown"
              data-delay=".4s" key={idx}
             
              >
                 <Link to={`/project/${service.slug}`}>
              <div className="card h-100"  >
                <img
                  className="card-img-top"
                  src={`${imgUrl}${service.image}`} alt="Our Projects"
                  height="180"
                />
                <div className="card-body">
              
                  <h4 className="card-title text-center"> {service.title?service.title:""}</h4>
                 
                </div>
              </div>
              </Link>
            </div>

            ))
      }

     
      </div>
    </div>
  </section>



    </>
  )
    }

</>
  )
}

export default ProjectsPage