 import React, { useEffect, useState } from "react";
import siteSetting from "../../services/settingServices";

const TopHeaderComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const settingData = async () => {
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setData(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);
  return (
    <div className="header-top second-header d-none d-md-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-8 d-none d-lg-block">
            <div className="header-cta">
              <ul>
                {data && data.email && (
                  <li>
                    <i className="icon fal fa-envelope"></i>
                    <span>{data.email ? data.email : ""}</span>
                  </li>
                )}
                {data && data.phone && (
                  <li>
                    <i className="icon fal fa-phone"></i>
                    <span>{data.phone ? data.phone : ""}</span>
                  </li>
                )}

                {data && data.phone2 && (
                  <li>
                    <i className="icon fal fa-mobile"></i>
                    <span>{data.phone2 ? data.phone2 : ""}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 d-none d-lg-block text-right">
            <div className="header-social">
              <span>
                {data && data.facebook
 && (
                  <a
                    href={`${data.facebook
 ? data.facebook
 : ""}`}
                    target="_blank"
                    title="Facebook"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                )}
                {data && data.linkedin
 && (
                  <a
                    href={`${data.linkedin
 ? data.linkedin
 : ""}`}
                    target="_blank"
                    title="LinkedIn"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                )}

                {data && data.twitter && (
                  <a
                    href={`${data.twitter ? data.twitter : ""}`}
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                )}

                {data && data.instagram && (
                  <a
                    href={`${data.instagram ? data.instagram : ""}`}
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeaderComponent;
