import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import MyRoute from "./MyRoute";


function App() {
  return (
    <div >
  <MyRoute/>
    </div>
  );
}

export default App;
